(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/tipsen-bet-button/assets/javascripts/tipsen-bet-button.js') >= 0) return;  svs.modules.push('/components/lb-ui/tipsen-bet-button/assets/javascripts/tipsen-bet-button.js');
"use strict";


const {
  Outcome
} = svs.components.lbUi.outcome;
const TipsenBetButton = _ref => {
  let {
    branding = '',
    className,
    dataTestId,
    handleClick = () => {},
    hasUSign = false,
    isCorrect = false,
    isClickable = false,
    isReduceFree = false,
    isSelected = false,
    sign = ''
  } = _ref;
  const buttonClassNames = ['pg_tipsen_bet_button'];
  if (className) {
    buttonClassNames.push(className);
  }
  return React.createElement("button", {
    "aria-selected": isSelected,
    className: buttonClassNames.join(' '),
    "data-testid": dataTestId,
    disabled: !isClickable,
    onClick: handleClick,
    type: "button"
  }, React.createElement(Outcome, {
    branding: branding,
    hasUSign: hasUSign,
    isCorrect: isCorrect,
    isDisabled: !isClickable,
    isReduceFree: isReduceFree,
    isSelected: isSelected,
    sign: sign
  }));
};
setGlobal('svs.components.lbUi.tipsenBetButton.TipsenBetButton', TipsenBetButton);

 })(window);